import 'preact/debug'
import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import {
  HeroSection,
  Page,
  Seo,
} from "gatsby-theme-portfolio-minimal";
import SEO from './components/seo.js';
import loadable from '@loadable/component'

const InterestsSection = loadable(() => import('gatsby-theme-portfolio-minimal/src/sections/Interests/index.tsx'))
const AboutSection = loadable(() => import('gatsby-theme-portfolio-minimal/src/sections/About/index.tsx'))
const ContactSection = loadable(() => import('gatsby-theme-portfolio-minimal/src/sections/Contact/index.tsx'))
const Timeline = loadable(() => import('./components/timeline.js'))
const Projects = loadable(() => import('./components/projects.js'))

export default function IndexPage() {
  return (
    <>
      <Seo title="Julian Weng" />
      {/* <Page useSplashScreenAnimation> */}
      <Page>
        <HeroSection sectionId="hero" />
        {/* <ArticlesSection sectionId="articles" heading="Latest Articles" sources={['Medium']} /> */}
        <AboutSection sectionId="about" heading="About Me" />
        <InterestsSection sectionId="skills" heading="Skills" />
        <Projects sectionId="projects" heading="Projects" />
        <Timeline sectionId="timeline" heading="Experiences" />
        <ContactSection sectionId="contact" heading="Questions? Opportunities?" />
      </Page>
    </>
  );
}

export const Head = () => (
  <SEO>
  </SEO>
)
/* Override for image */